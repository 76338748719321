import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Location from '../components/Location'
import Section from '../components/Section'
import SEO from '../components/seo'
import SliceZone from '../components/SliceZone'
import { RichText } from 'prismic-reactjs'

const ServicePage = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicService.edges[0].node
  const formatText = (input) => {
    return input[0].toUpperCase() + input.slice(1).replace(/-/g, ' ');
  }
  const SeoTitle = RichText.asText(document.data.meta_title.raw) || formatText(document.uid);
  const title = RichText.asText(document.data.page_title.raw) || formatText(document.uid);
  const SeoDescription = RichText.asText(document.data.meta_description.raw);
  const SeoFollow = document.data.seo_robots;

  return (
    <Layout>
        <SEO title={SeoTitle} description={SeoDescription} noFollow={SeoFollow} />
        <Section pageTitle={title}>
          <SliceZone sliceZone={document.data.body} />
          <Location />
        </Section>
    </Layout>
  )
}

export const query = graphql`
query ServiceQuery($uid: String) {
  allPrismicService(filter: {uid: {eq: $uid}}) {
    edges {
      node {
        uid
        data {
          meta_description {
            raw
          }
          page_title {
            raw
          }
          meta_title {
            raw
          }
          seo_robots
          body {
            ... on PrismicServiceBodyText {
              slice_type
              primary {
                content {
                  raw
                }
                columns
              }
              items {
                column_text {
                  raw
                }
                column_image {
                  url
                  thumbnails
                  alt
                }
                column_heading {
                  raw
                }
              }
            }
            ... on PrismicServiceBodyQuote {
              slice_type
              primary {
                quote {
                  raw
                }
              }
            }
            ... on PrismicServiceBodyFullWidthImage {
              slice_type
              primary {
                full_width_image {
                  url
                  thumbnails
                }
              }
            }
            ... on PrismicServiceBodyImageGallery {
              slice_type
              primary {
                gallery_title {
                  raw
                }
              }
              items {
                image {
                  url
                  thumbnails
                  alt
                }
                image_description {
                  raw
                }
                link {
                  url
                  type
                  uid
                }
                link_label {
                  raw
                }
              }
            }
            ... on PrismicServiceBodyImageHighlight {
              slice_type
              primary {
                featured_image {
                  url
                  thumbnails
                  alt
                }
                title {
                  raw
                }
                description {
                  raw
                }
                link {
                  url
                  type
                  uid
                }
                link_label {
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default ServicePage